import { HttpErrorResponse, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeAny } from 'sfx-commons';
import { LOGOUT_REASON } from '../../constants';
import { ROUTES as API_ROUTES } from '../../constants/api-routes';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { ProjectsService } from '../../projects/services/projects.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    constructor(private readonly authSvc: AuthenticationService,
                private readonly diagnosticsSvc: DiagnosticsService,
                private readonly projectsSvc: ProjectsService,
                private readonly sessionStorageSvc: SessionStorageService) {}

    public handleError(request: HttpRequest<SafeAny>, error: HttpErrorResponse): boolean {
        if (error.error instanceof ErrorEvent) {
            const value = error.error.message;
            this.diagnosticsSvc.log({ key: 'ErrorHandlerSvc.handleError(ErrorEvent)', value });
            console.error('An error occurred:', value);
            return true;
        } else {
            this.diagnosticsSvc.log({ key: 'ErrorHandlerSvc.handleError(else)', value: JSON.stringify(error) });
            console.error(`HTTP ${ error.status }: `, error);
            return this.handleServerError(request, error);
        }
    }

    private handleServerError(request: HttpRequest<SafeAny>, error: HttpErrorResponse): boolean {
        let shouldRethrowError = true;

        switch (error.status) {
            case HttpStatusCode.Unauthorized:
                void this.handleUnauthorizedError(request);
                shouldRethrowError = false;
                break;
            case HttpStatusCode.Forbidden:
                this.handleForbiddenError();
                break;
            // possible further custom status code handling
            default:
                break;
        }

        return shouldRethrowError;
    }

    private async handleUnauthorizedError(request: HttpRequest<SafeAny>): Promise<void> {
        if (request.url.includes(API_ROUTES.LOGOUT)) {
            return;
        }

        if (request.url.includes(API_ROUTES.CURRENT_USER) ||
            request.url.includes(API_ROUTES.LOGIN)) {
            this.sessionStorageSvc.set(SESSION_STORAGE_KEY.USER_LOGOUT, true);
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
            const authStatus = this.authSvc.getAuthStatus();
            if (authStatus?.isAuthenticated) {
                await this.authSvc.logout(true, false, LOGOUT_REASON.UNAUTHORIZED);
            }
            return;
        }

        await this.authSvc.logout(true, true, LOGOUT_REASON.UNAUTHORIZED);
    }

    private handleForbiddenError(): void {
        this.projectsSvc.signalAccessLostToOpenedProject();
    }
}