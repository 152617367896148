import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SafeAny } from 'sfx-commons';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private errorHandlerSvc: ErrorHandlerService) {}

    public intercept(request: HttpRequest<SafeAny>, next: HttpHandler) {
        return next
            .handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const shouldRethrowError = this.errorHandlerSvc.handleError(request, error);

                    if (shouldRethrowError) {
                        return throwError(() => error);
                    }

                    return NEVER;
                }
            )
        );
    }
}