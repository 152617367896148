<div class="page-wrapper outdated-browser-container" *ngIf="!isModernBrowser; else appWrapper">
    <sfx-logo path="swissfex.svg#swissfex"></sfx-logo>
    <div>{{ 'global.browser_not_supported' | translate }}</div>
</div>
<ng-template #appWrapper>
    <div class="page-wrapper" *ngIf="isAppLoading && (isRouteGuardWaitingForUserActionObservable | async) !== true; else outlet">
        <sfx-loader></sfx-loader>
    </div>
    <ng-template #outlet>
        <sfx-sticky-bar *ngIf="authStatus?.isAuthorized && (isBackofficeUser && displayAdminBanner || news?.length)">
            <div *ngIf="isBackofficeUser && displayAdminBanner" class="admin-message" (click)="onAdminBannerClicked()">{{ 'auth.backoffice_user_info' | translate }}</div>
            <app-news-bar *ngIf="news?.length" [news]="news"></app-news-bar>
        </sfx-sticky-bar>
        <router-outlet></router-outlet>
        <router-outlet name="sc"></router-outlet>
        <router-outlet name="cfg"></router-outlet>
        <router-outlet name="n"></router-outlet>
    </ng-template>
</ng-template>

<div *ngIf="areDiagnosticsEnabled" style="position: absolute; top: 0; z-index: 1000; background-color: rgba(255, 255, 255, 0.8); color: #000;">
    <sfx-list [items]="diagnosticLogs | async | slice:0: 10" bullet="-">
        <ng-template #listItem let-item>{{ item.date }}: {{ item.key }} - {{ item.value }}</ng-template>
    </sfx-list>

    <sfx-button type="main" (clicked)="disabledDiagnostics()" label="Close"></sfx-button>
</div>

<app-session-status-notification></app-session-status-notification>