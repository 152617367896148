import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthConfig, OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { de_DE, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { AppModuleRouting } from './app.module.routing';
import { CommonComponentsModule } from './common-components/common-components.module';
import { IdentityProvider } from './constants';
import { CoreModule } from './core/core.module';
import { AppInitializerService } from './core/services/app-initializer.service';
import { LOCAL_STORAGE_KEY } from './core/services/local-storage.service';
import { SfxErrorHandler } from './core/sfx-error-handler/sfx-error-handler';
import { RouterUtils } from './core/utils/router.utils';
import { getDefaultSSOConfigs } from './core/utils/sso-config.utils';
import { Key4TrainingCompletedComponent } from './key4-training-completed/key4-training-completed.component';
import { LanguageBaseComponent } from './language-base.component';
import { LoginAzureComponent } from './login-azure/login-azure.component';
import { MadMissingTranslationHandler } from './mad-translate/mad-missing-translations-handler';
import { HttpTranslateLoaderFactory, MadTranslateModule } from './mad-translate/mad-translate.module';
import { AuthenticationService } from './projects/services/authentication.service';

const configs: { [key in IdentityProvider]?: AuthConfig } = getDefaultSSOConfigs();

const configureAsyncRoutesAndAuth = (appInitSvc: AppInitializerService, oAuthSvc: OAuthService, authSvc: AuthenticationService) => {
    return async () => {
        try {
            await appInitSvc.init();

            // TODO: if and when login and logging-in urls have to be localized, come up with a better solution
            const currentLang = RouterUtils.getLanguageFromUri();
            const defaultIdentitySvc = window.location.host === environment.originsPerUserType.Advisor ?
                IdentityProvider.SwissLife : IdentityProvider.DfsIdentityServer;
            const activeIdentityService = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID) || defaultIdentitySvc;
            configs[activeIdentityService].redirectUri = `${ window.location.origin }/${ currentLang.toLowerCase() }/logging-in`;

            if (activeIdentityService === IdentityProvider.DfsIdentityServer.toString()) {
                configs[activeIdentityService].customQueryParams = { language: currentLang.toLowerCase() };
            }

            authSvc.configureAuthService(+activeIdentityService, configs[activeIdentityService]);
            await oAuthSvc.loadDiscoveryDocumentAndTryLogin();
        } catch (ex) {
            console.error(ex);
        }
    };
};

@NgModule({
    declarations: [
        AppComponent,
        Key4TrainingCompletedComponent,
        LanguageBaseComponent,
        LoginAzureComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MadMissingTranslationHandler },
            useDefaultLang: false
        }),

        CommonComponentsModule,
        CoreModule,
        MadTranslateModule,
        OAuthModule.forRoot(),

        AppModuleRouting
    ],
    providers: [
        { provide: NZ_CONFIG, useValue: { notification: { nzPlacement: 'bottomRight' }}},
        { provide: NZ_I18N, useValue: de_DE },
        { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 }},
        AppInitializerService,
        OAuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAsyncRoutesAndAuth,
            deps: [AppInitializerService, OAuthService, AuthenticationService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: SfxErrorHandler }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeIt, 'it', localeItExtra);
