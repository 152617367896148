import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../projects/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private readonly authSvc: AuthenticationService) {}

    public intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let authToken;
        if (this.authSvc) {
            authToken = this.authSvc.getBearerToken();
        }

        if (request && request.url &&
            !request.url.includes('token') &&
            !request.url.includes(environment.translationsUrl) &&
            authToken && request.method !== 'JSONP') {
            request = request.clone({
                setHeaders: {
                    Authorization: authToken
                }
            });
        }

        return next.handle(request);
    }
}
