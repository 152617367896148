import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    ActivationStart,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, Subscription } from 'rxjs';
import { SfxCommonsLocalizerService, tryToUnsubscribeFromSubscription } from 'sfx-commons';
import { environment } from '../environments/environment';
import {
    LANGUAGE_FILE_NAME_SUFFIX,
    MOMENT_DATE_FORMATS_PER_LANGUAGE,
    MOMENT_DATE_MONTH_FORMATS_PER_LANGUAGE,
    SafeAny
} from './constants';
import { DiagnosticLog } from './constants/types';
import { IApplicationUser } from './core/models/IApplicationUser';
import { DomUpdateSchedulerService } from './core/services/dom-update-scheduler.service';
import { RealTimeNotificationService } from './core/services/real-time-notification.service';
import { RouterService } from './core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from './core/services/session-storage.service';
import { LocationUtils } from './core/utils/location.utils';
import { ObjectUtils } from './core/utils/object.utils';
import { RouterUtils } from './core/utils/router.utils';
import { DiagnosticsService } from './diagnostics/services/diagnostics.service';
import { MadTranslateService } from './mad-translate/services/mad-translate.service';
import { ProjectStatus } from './projects/models/enums/projectStatus';
import { INews } from './projects/models/INews';
import { AuthenticationService, IAuthStatus } from './projects/services/authentication.service';
import { AuthorizationService } from './projects/services/authorization.service';
import { NewsService } from './projects/services/news.service';
import { ProjectsService } from './projects/services/projects.service';
import { Userpilot } from 'userpilot';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
    public areDiagnosticsEnabled = false;
    public diagnosticLogs: Observable<Array<DiagnosticLog>>;
    public isAppLoading = true;
    public isModernBrowser = true;
    public isBackofficeUser = false;
    public authStatus: IAuthStatus;
    public news: Array<INews> = [];
    public displayAdminBanner = true;
    public isRouteGuardWaitingForUserActionObservable: Observable<boolean>;

    private currentUser: IApplicationUser;
    private language: string;
    private projectStatusUpdateNotificationsSubscription: Subscription;
    private messageNotificationsSubscription: Subscription;
    private readonly diagnosticsQueryParamName = 'sfxdiag';

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly authenticationSvc: AuthenticationService,
                private readonly authorizationSvc: AuthorizationService,
                private readonly cdr: ChangeDetectorRef,
                private readonly diagnosticsSvc: DiagnosticsService,
                @Inject(DOCUMENT) private document: Document,
                private readonly domUpdateSchedulerSvc: DomUpdateSchedulerService,
                private readonly madTranslateSvc: MadTranslateService,
                private readonly newsSvc: NewsService,
                private readonly nzNotificationSvc: NzNotificationService,
                private readonly rtNotificationSvc: RealTimeNotificationService,
                private readonly projectSvc: ProjectsService,
                private readonly router: Router,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly sfxCommonsLocalizerSvc: SfxCommonsLocalizerService,
                private readonly translate: TranslateService) {
        const { queryParams } = LocationUtils.parseQueryString() || {};
        this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(queryParams && queryParams[this.diagnosticsQueryParamName]);
        this.diagnosticLogs = this.diagnosticsSvc.logs;

        this.translate.onLangChange
            .pipe(untilDestroyed(this))
            .subscribe(({ lang }) => {
                this.language = lang;
                this.document.documentElement.lang = lang;
            });

        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((params) => {
                if (params[this.diagnosticsQueryParamName]) {
                    this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(params[this.diagnosticsQueryParamName]);
                }
            });

        window.onpopstate = async () => {
            const oldLanguage = this.router.url.split('/')[1];
            const newLanguage = window.location.pathname.split('/')[1];
            if (oldLanguage !== newLanguage) {
                await this.madTranslateSvc.setSelectedLanguage(ObjectUtils.getKeyForValue(LANGUAGE_FILE_NAME_SUFFIX, newLanguage));
            }
        };

        this.isModernBrowser = this.detectIfModernBrowser();
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    public async ngOnInit(): Promise<void> {
        this.isRouteGuardWaitingForUserActionObservable = this.routerSvc.isRouteGuardWaitingForUserActionObservable;
        this.displayAdminBanner = !this.sessionStorageSvc.get(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
        await this.madTranslateSvc.initialize();
        this.sfxCommonsLocalizerSvc.setDateFormatsPerLanguage(MOMENT_DATE_FORMATS_PER_LANGUAGE);
        this.sfxCommonsLocalizerSvc.setDateMonthFormatsPerLanguage(MOMENT_DATE_MONTH_FORMATS_PER_LANGUAGE);
        let routePaths = [];
        let routeComponents = {};
        this.router.events.pipe(untilDestroyed(this)).subscribe((event): void => {
            switch (true) {
                case event instanceof ActivationStart:
                    // eslint-disable-next-line no-case-declarations
                    const eventDup = event as SafeAny;
                    if (event && eventDup.snapshot && eventDup.snapshot.routeConfig && eventDup.snapshot.routeConfig.component) {
                        const lastActivatedComponentName = eventDup.snapshot.routeConfig.component.name;
                        const lastActivatedRoute = RouterUtils.getFullPathFromSnapshot(eventDup.snapshot);
                        routePaths.push(lastActivatedRoute);
                        routeComponents[lastActivatedRoute] = lastActivatedComponentName;
                    }
                    break;
                case event instanceof NavigationStart:
                    this.isAppLoading = true;
                    break;
                case event instanceof NavigationEnd:
                case event instanceof NavigationError:
                case event instanceof NavigationCancel:
                    // eslint-disable-next-line no-case-declarations
                    const newActiveComponentIndex = routePaths.findIndex((item) => item === (event as SafeAny).urlAfterRedirects);
                    if (newActiveComponentIndex > -1) {
                        const toRemove = routePaths.splice(newActiveComponentIndex + 1);
                        toRemove.map(toRemoveKey => delete routeComponents[toRemoveKey]);
                    } else {
                        if ((event as SafeAny).urlAfterRedirects === '/' || routePaths.length) {
                            const toRemoveIndex = routePaths.length - 1 - routePaths.reverse().findIndex(item => item === '/');
                            if (toRemoveIndex + 1 === routePaths.length) {
                                routePaths = [];
                                routeComponents = {};
                            } else {
                                const toRemove = routePaths.splice(toRemoveIndex);
                                toRemove.map(toRemoveKey => delete routeComponents[toRemoveKey]);
                            }
                        }
                    }

                    this.domUpdateSchedulerSvc.updatePathAndComponents(routePaths, routeComponents);
                    this.domUpdateSchedulerSvc.updateActiveComponent();
                    this.isAppLoading = false;
                    if (!!environment.features.userPilot.isEnabled) {
                        Userpilot.reload();
                    }
                    break;
            }
        });

        setTimeout(() => {
            if (this.isAppLoading) {
                this.isAppLoading = false;
            }
        }, 2500);

        this.authenticationSvc.authStatus
            .pipe(untilDestroyed(this))
            .subscribe(async authData => {
                this.authStatus = authData;

                if (authData) {
                    if (authData.isAuthenticated) {
                        if (authData.isAuthorized) {
                            await this.processLoggedInImplications();
                        } else {
                            this.currentUser = await this.authenticationSvc.getCurrentUser();
                            this.initializeUserPilot();
                        }
                    } else {
                        await this.processLoggedOutImplications();
                    }
                }
            });

        this.authorizationSvc.roleAndGlobalPermissionMapsObservable
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isBackofficeUser = this.authorizationSvc.isSfxAdmin);
    }

    public async disabledDiagnostics(): Promise<void> {
        this.diagnosticsSvc.disable();
        this.areDiagnosticsEnabled = this.diagnosticsSvc.tryActivateService(null);
        const queryParams = { ...this.activatedRoute.snapshot.queryParams };
        if (queryParams[this.diagnosticsQueryParamName]) {
            delete queryParams[this.diagnosticsQueryParamName];
            await this.router.navigate([], { queryParams: queryParams });
        }
    }

    public onAdminBannerClicked(): void {
        this.displayAdminBanner = false;
        this.sessionStorageSvc.set(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN, true);
    }

    private async processLoggedInImplications(): Promise<void> {
        await this.rtNotificationSvc.connect();

        tryToUnsubscribeFromSubscription(this.projectStatusUpdateNotificationsSubscription);
        this.projectStatusUpdateNotificationsSubscription = this.rtNotificationSvc.projectStatusUpdateNotifications
            .pipe(untilDestroyed(this))
            .subscribe(projectStatusUpdateNotification => {
                const {
                    projectId,
                    status,
                    competenceCenterFeedback,
                    isProjectGroupFixed,
                    isFrozen,
                    revokeReason,
                    revokeReasonComment
                } = projectStatusUpdateNotification.payload;

                let openedProject = this.projectSvc.getOpenedProject();

                if (status === ProjectStatus.AdditionalDocumentsRequestedByCC ||
                    status === ProjectStatus.SubmittedToLenders ||
                    status === ProjectStatus.FinancingConfirmationReceived) {
                    try {
                        this.projectSvc.getProject(projectId);
                    } catch (ex) {
                        console.error(ex);
                    }
                }

                if (openedProject && openedProject.id === projectId) {
                    openedProject = {
                        ...openedProject,
                        projectStatus: status,
                        competenceCenterReview: {
                            ...openedProject.competenceCenterReview,
                            feedback: competenceCenterFeedback
                        },
                        isProjectGroupFixed,
                        isFrozen,
                        revokeReason,
                        revokeReasonComment
                    };
                    this.projectSvc.updateProjectInCache(openedProject);
                }
            });

        tryToUnsubscribeFromSubscription(this.messageNotificationsSubscription);
        this.messageNotificationsSubscription = this.rtNotificationSvc.messageNotifications
            .pipe(untilDestroyed(this))
            .subscribe(messageNotification => {
                // TODO: remove when not necessary for illustrating the functionality
                const { projectId, channel, message, sender, dateTime } = messageNotification.payload;
                const title = `${ projectId } - ${ channel }`;
                const body = `${ sender }: ${ message } @ ${ dateTime }`;
                this.nzNotificationSvc.create(
                    'info',
                    title,
                    body
                );
            });

        this.news = await this.newsSvc.getNews();
    }

    private async processLoggedOutImplications(): Promise<void> {
        await this.rtNotificationSvc.disconnect();
        tryToUnsubscribeFromSubscription(this.projectStatusUpdateNotificationsSubscription);
        tryToUnsubscribeFromSubscription(this.messageNotificationsSubscription);
    }

    private detectIfModernBrowser(): boolean {
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return false;
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            return false;
        }

        return true;
    }

    private initializeUserPilot(): void {
        if (!!environment.features.userPilot.isEnabled) {
            Userpilot.initialize(environment.features.userPilot.key);
            Userpilot.identify(this.currentUser.mail, {
                name: `${ this.currentUser.firstname } ${ this.currentUser.lastname }`,
                email: this.currentUser.mail,
                locale_code: this.language
            });
        }
    }
}
