import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MadMissingTranslationHandler implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams): string {
        if (params.interpolateParams && params.interpolateParams['fallback']) {
            return params.translateService.instant(params.interpolateParams['fallback']);
        }
        return params.key;
    }
}
