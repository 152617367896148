import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { DiagnosticLoginInterceptor } from './services/diagnostic-login.interceptor';
import { ErrorInterceptor } from './services/error.interceptor';
import { PendingSaveRequestCountInterceptor } from './services/pending-save-request-count.interceptor';
import { TokenInterceptor } from './services/token.interceptor';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzNotificationModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PendingSaveRequestCountInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DiagnosticLoginInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ]
})
export class CoreModule {}
