export {};

declare global {
    interface String {
        capitalizeFirstWord(): string;
        lowercaseFirstWord(): string;
    }
}

String.prototype.capitalizeFirstWord = function(this: string) {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.lowercaseFirstWord = function(this: string) {
    return this.charAt(0).toLowerCase() + this.slice(1);
};
